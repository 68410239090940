import retry from 'retry'
import { AppDispatch } from '../../../store'

export interface ChatEventPayloadUser {
  alias: string
  avatar: string
}

export interface Message {
  me: string
  type: string
}

export enum ChatActions {
  GET_SQUAD = 'GET_SQUAD',
  CREATE_CHAT = 'CREATE_CHAT',
  LIST_CHATS = 'LIST_CHATS',
  FETCH_CHAT_MESSAGES = 'FETCH_CHAT_MESSAGES',
  FETCH_CHAT_MESSAGES_UNTIL = 'FETCH_CHAT_MESSAGES_UNTIL',
  MARK_CHAT_MESSAGES_SEEN = 'MARK_CHAT_MESSAGES_SEEN',
  CREATE_CHAT_MESSAGE = 'CREATE_CHAT_MESSAGE',
  MARK_CHAT_MESSAGE_DELETED = 'MARK_CHAT_MESSAGE_DELETED',
  EDIT_CHAT_MESSAGE = 'EDIT_CHAT_MESSAGE',
  ARCHIVE_CHAT = 'ARCHIVE_CHAT',
  UNARCHIVE_CHAT = 'UNARCHIVE_CHAT',
  UPDATE_CHAT_TITLE = 'UPDATE_CHAT_TITLE',
  UPDATE_CHAT_PEERS = 'UPDATE_CHAT_PEERS',
  WEBRTC_SIGNALING = 'WEBRTC_SIGNALING',
  CREATE_CHAT_MESSAGE_REACTION = 'CREATE_CHAT_MESSAGE_REACTION',
  REMOVE_CHAT_MESSAGE_REACTION = 'REMOVE_CHAT_MESSAGE_REACTION',
  START_DIAL = 'START_DIAL',
  STOP_DIAL = 'STOP_DIAL',
}
export abstract class WebsocketConnection {
  protected _dispatch: AppDispatch
  protected _ws?: WebSocket
  protected _jwt: string
  protected _me: string
  protected disconnectTimeout: ReturnType<typeof setTimeout> | undefined
  protected _closed: boolean

  constructor(dispatch: AppDispatch, jwt: string, me: string) {
    console.debug('WebsocketConnection::constructor')
    this._dispatch = dispatch
    this._jwt = jwt
    this._me = me
    this._closed = false
  }

  public connect(url: string) {
    const operation = retry.operation({
      forever: true,
      factor: 1.5,
      minTimeout: 1000,
      maxTimeout: 5000,
      randomize: true,
    })

    operation.attempt((currentAttempt) => {
      if (this._closed) {
        console.log('operation.attempt this._closed')
        operation.stop()
        return
      }

      this.disconnect(false)
      this._ws = new WebSocket(url)

      this._ws.onmessage = (msg) => {
        this.updateDisconnectTimeout()

        try {
          const data = JSON.parse(msg.data)
          data.me = this._me
          this.handleMessage(data)
        } catch (e) {
          console.error(e)
        }
      }

      this._ws.onopen = () => {
        console.debug('WebsocketConnection::onopen')
        this.updateConnectionState(true)
        this.updateDisconnectTimeout()
      }

      this._ws.onclose = (e) => {
        console.debug('WebsocketConnection::onclose', e)
        this.updateConnectionState(false)

        operation.retry(new Error('Closed'))
      }

      this._ws.onerror = (e) => {
        console.debug('WebsocketConnection::onerror', e)
      }
    })
  }

  public abstract handleMessage(data: Message): void

  public send(data: string) {
    try {
      console.log('this._ws:APPWEBSOCKER', data)
      this._ws?.send(data)
    } catch (e) {
      console.error('APPWEBSOCKET ERROR ' + e, data)
    }
  }

  public abstract updateDisconnectTimeout(): void

  public abstract updateConnectionState(connected: boolean): void

  public disconnect(definetly: boolean = true) {
    console.debug('WebsocketConnection::disconnect definetly=' + definetly)
    if (definetly) {
      this._closed = true
    }

    if (this._ws) {
      console.debug('WebsocketConnection::disconnect this._ws')
      this._ws.onopen = null
      this._ws.onclose = null
      this._ws.onerror = null
      this._ws.onmessage = null
      this._ws.close()
      this._ws = undefined
      this.updateConnectionState(false)
      // clearTimeout(this.disconnectTimeout)
    }
  }
}
